import { NumericInput } from "components/NumericInput/NumericInput";
import { updateOrder } from "containers/CartPage/slice";
import { useDispatch } from "react-redux";

interface IQuantityComponentProps {
  storeId?: string;
  orderId?: string;
  stock?: number;
  productId?: string;
  quantity?: number;
}

export const QuantityComponent = ({
  storeId,
  orderId,
  stock,
  productId,
  quantity,
}: IQuantityComponentProps) => {
  const dispatch = useDispatch();

  const handleUpdate = (newQuantity: number) => {
    if (storeId && orderId && productId) {
      dispatch(
        updateOrder({ storeId, orderId, productId, quantity: newQuantity }),
      );
    }
  };

  return (
    <NumericInput
      style={{ width: "6rem", borderRadius: "5px", padding: "2px" }}
      value={quantity ?? 0}
      updateValue={handleUpdate}
      minValue={0}
      maxValue={stock}
    />
  );
};
