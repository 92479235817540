import { useState } from "react";
import { Wrapper } from "../atoms/Wrapper";
import {
  Divider,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { ShoppingCart } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "containers/App/slice";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { RouteVar } from "utils/constants";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";
import { Popper } from "common/components/Popper";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import React from "react";

export const TopbarProfile = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
    undefined,
  );

  const options = [
    {
      text: "Editează Profil",
      icon: <ManageAccountsIcon />,
      action: () => {
        setAnchorEl(undefined);
        navigate(RouteVar.profilePage.Route);
      },
      active: user?.role === IUserRoleEnum.Store,
    },
    {
      text: "Coș de cumpărături",
      icon: <ShoppingCart />,
      action: () => {
        setAnchorEl(undefined);
        navigate(RouteVar.cartPage.Route);
      },
      active: user?.role === IUserRoleEnum.Store,
    },
    {
      text: "Delogare",
      icon: <ExitToAppIcon />,
      action: () => dispatch(logoutUser()),
      active: true,
    },
  ];

  return (
    <Wrapper>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <AccountCircle sx={{ width: "2rem", height: "2rem" }} />
      </IconButton>
      <Popper
        anchor={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        open={Boolean(anchorEl)}
      >
        <Stack
          sx={{
            width: "20rem",
          }}
        >
          {options.map((option, index) => {
            const { action, active, icon, text } = option;
            if (!active) {
              return <React.Fragment key={index} />;
            }
            return (
              <>
                <MyMenuItem
                  key={index}
                  text={text}
                  action={action}
                  icon={icon}
                />
                {index < options.length - 1 && (
                  <Divider
                    sx={{ width: "100%", backgroundColor: palette.grey[300] }}
                  />
                )}
              </>
            );
          })}
        </Stack>
      </Popper>
    </Wrapper>
  );
};

const MyMenuItem = ({
  action,
  icon,
  text,
}: {
  icon: JSX.Element;
  text: string;
  action: () => void;
}) => {
  return (
    <MenuItem
      sx={{ justifyContent: "center", position: "relative" }}
      onClick={action}
    >
      <Icon
        sx={{
          position: "absolute",
          left: "10%",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        {icon}
      </Icon>
      {text}
    </MenuItem>
  );
};
