import { loginUser } from "containers/App/slice";
import {
  selectLoading,
  selectLoginError,
  selectUser,
} from "containers/App/slice/selectors";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JsonData from "../../../data/data.json";
import { Button, Stack, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export interface AuthForm {
  email: string;
  password: string;
}

interface ILandingPageData {
  Header: {};
  Features: {};
  About: {};
  Services: {};
  Gallery: {};
  Testimonials: {};
  Team: {};
  Contact: {};
}

export function NoUserMenu(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const loginError = useSelector(selectLoginError);
  const [landingPageData, setLandingPageData] = useState<ILandingPageData>();
  const loading = useSelector(selectLoading);

  useEffect(() => {
    setLandingPageData(JsonData);
    if (user) {
      navigate("/");
    }
  }, [user]);

  const handleLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(loginUser({ email, password }));
  };

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(loginError, { variant: "error" });
    }
  }, [loginError]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={({ email, password }) => {
        handleLogin({ email, password });
      }}
    >
      {(formikProps: FormikProps<AuthForm>) => {
        return (
          <Form>
            <Stack flexDirection="row" justifyContent="flex-end" gap="0.5rem">
              <Field sx={{ width: "100%" }} name="email">
                {(props: FieldProps<string, AuthForm>) => {
                  return (
                    <TextField
                      id="email"
                      onBlur={() =>
                        props.form.setFieldTouched(props.field.name, true)
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      name="Email"
                      placeholder={"Email"}
                      color="secondary"
                      required
                      autoFocus
                      value={props.field.value}
                      onChange={(e) => {
                        props.form.setFieldTouched(props.field.name, false);
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value,
                          true,
                        );
                      }}
                    />
                  );
                }}
              </Field>
              <Field name="password">
                {(props: FieldProps<string, AuthForm>) => {
                  return (
                    <TextField
                      id="password"
                      size="small"
                      onBlur={() =>
                        props.form.setFieldTouched(props.field.name, true)
                      }
                      InputLabelProps={{ shrink: true }}
                      color="secondary"
                      name="Password"
                      placeholder={"Parola"}
                      type="password"
                      value={props.field.value}
                      onChange={(e) => {
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value,
                          true,
                        );
                      }}
                    />
                  );
                }}
              </Field>
              <LoadingButton
                type="submit"
                loading={loading}
                color="primary"
                variant="contained"
                disableElevation
                sx={{ fontWeight: 600 }}
                onClick={formikProps.submitForm}
              >
                Intra in cont
              </LoadingButton>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
