import { TextField } from "@mui/material";
import { CSSProperties } from "react";

interface INumericInputProps {
  value: number;
  updateValue: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  style?: CSSProperties;
}

export const NumericInput = ({
  style,
  value,
  updateValue,
  minValue,
  maxValue,
}: INumericInputProps) => {
  return (
    <TextField
      size="small"
      type="number"
      style={style ? style : { maxWidth: "70px", marginRight: "10px" }}
      value={value}
      onChange={(event) => {
        const value = event.target.value;
        if (value === "00") {
          event.target.value = "0";
          return;
        }
        const sanitizedValue =
          value.startsWith("0") && value.length > 1
            ? value.replace(/^0+/, "")
            : value;

        const numericValue = sanitizedValue.replace(/\D/g, "");
        if (numericValue !== "") {
          event.target.value = numericValue;
          const num = parseInt(numericValue, 10);
          if (minValue && num < minValue) updateValue(0);
          else if (maxValue && num > maxValue) updateValue(maxValue);
          else {
            updateValue(num);
          }
        } else {
          updateValue(0);
        }
      }}
      inputProps={{ min: minValue, max: maxValue }}
    />
  );
};
