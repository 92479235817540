import { Text } from "atoms";
import { TextField } from "@mui/material";

interface IFormInputProps {
  name?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: string;
}

export const FormInput = ({
  name,
  value,
  onChange,
  disabled,
  type,
}: IFormInputProps) => {
  return (
    <div className="flex flex-row mt-3">
      <Text style={{ marginBottom: "0.25rem" }} bold>{`${name}:`}</Text>
      <TextField
        size="small"
        fullWidth
        type={type}
        disabled={disabled ? true : false}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
