import Modal from "components/Modal";
import { createProducer, updateProducer } from "containers/ProducersPage/slice";
import { selectPagination } from "containers/ProducersPage/slice/selectors";
import { IProducerIn } from "openapi/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProducerIn } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { Button, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Text } from "atoms";
import { getPaymentMethodDropdownOptions } from "utils/enumUtils";

interface IProducerModalProps {
  onClose: () => void;
  producer?: IProducerIn;
}

export const AddProducerModal = ({
  onClose,
  producer,
}: IProducerModalProps) => {
  const [selectedProducer, setSelectedProducer] = useState<IProducerIn>(
    new ProducerIn(),
  );
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (producer) {
      setSelectedProducer(producer);
    }
  }, []);

  const onAction = () => {
    if (producer) {
      dispatch(updateProducer({ producer: selectedProducer, pagination }));
    } else {
      dispatch(createProducer({ producer: selectedProducer, pagination }));
    }
    setSelectedProducer(new ProducerIn());
    onClose();
  };

  const updateProducerVariables = (key: keyof IProducerIn, value) => {
    setSelectedProducer({
      ...selectedProducer,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Stack gap="1rem" className="mt-3">
          <Text bold fontSize="20px" style={{ marginBottom: "2rem" }}>
            Informații producător
          </Text>
          <TextField
            size="small"
            label="Nume"
            fullWidth
            value={selectedProducer?.name ?? ""}
            onChange={(e) => {
              updateProducerVariables("name", e.target.value);
            }}
          />
          <TextField
            size="small"
            label="Adresa"
            fullWidth
            value={selectedProducer?.address?.address ?? ""}
            onChange={(e) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                address: e.target.value,
              });
            }}
          />
          <TextField
            size="small"
            label="Oras"
            fullWidth
            value={selectedProducer?.address?.city ?? ""}
            onChange={(e) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                city: e.target.value,
              });
            }}
          />
          <TextField
            size="small"
            label="Judet"
            fullWidth
            value={selectedProducer?.address?.county ?? ""}
            onChange={(e) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                county: e.target.value,
              });
            }}
          />
          <TextField
            size="small"
            label="Tara"
            fullWidth
            value={selectedProducer?.address?.country ?? ""}
            onChange={(e) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                country: e.target.value,
              });
            }}
          />
          <Text bold>Metodă de plată:</Text>
          <TextField
            select
            size="small"
            value={selectedProducer.paymentMethod?.toString() ?? ""}
            onChange={(e) => {
              updateProducerVariables("paymentMethod", e.target.value);
            }}
          >
            {getPaymentMethodDropdownOptions().map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.display}
              </MenuItem>
            ))}
          </TextField>
          <Text bold>Termen de plată:</Text>
          <TextField
            size="small"
            style={{ width: "25%" }}
            type="number"
            value={selectedProducer.paymentTerm}
            onChange={(e) => {
              updateProducerVariables("paymentTerm", e.target.value);
            }}
          />
        </Stack>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button variant="contained" onClick={onAction}>
            {producer ? "Editeaza" : "Adauga"}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
