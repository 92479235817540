import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { Button, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IUser, IUserRoleEnum } from "openapi/types";
import { User } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { createUser, updateUser } from "containers/UsersPage/slice";
import { getUserRoleDropdownOptions } from "utils/enumUtils";
import {
  selectProducerList,
  selectStoreList,
  selectWarehouseList,
} from "containers/UsersPage/slice/selectors";

interface IUserModalProps {
  onClose: () => void;
  user?: IUser;
}

export const AddUserModal = ({ onClose, user }: IUserModalProps) => {
  const [selectedUser, setSelectedUser] = useState<IUser>(new User());
  const [selectedPassword, setSelectedPassword] = useState<string>("");
  const pagination = useSelector(selectPagination);
  const warehouseList = useSelector(selectWarehouseList);
  const producerList = useSelector(selectProducerList);
  const storeList = useSelector(selectStoreList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setSelectedUser(user);
    }
  }, []);

  const onAction = () => {
    if (user) {
      dispatch(updateUser({ user: selectedUser, pagination }));
    } else {
      dispatch(
        createUser({
          user: selectedUser,
          password: selectedPassword,
          pagination,
        }),
      );
    }
    setSelectedUser(new User());
    onClose();
  };

  const updateUserVariables = (key: keyof IUser, value) => {
    setSelectedUser({
      ...selectedUser,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Stack gap="1rem" className="mt-3">
          <Text bold fontSize="20px" style={{ marginBottom: "2rem" }}>
            Informații user
          </Text>
          <TextField
            size="small"
            label="Nume"
            fullWidth
            value={selectedUser?.lastName ?? ""}
            onChange={(e) => {
              updateUserVariables("lastName", e.target.value);
            }}
          />
          <TextField
            size="small"
            label="Prenume"
            fullWidth
            value={selectedUser?.firstName ?? ""}
            onChange={(e) => {
              updateUserVariables("firstName", e.target.value);
            }}
          />
          <TextField
            size="small"
            label="Email"
            fullWidth
            value={selectedUser?.email ?? ""}
            onChange={(e) => {
              updateUserVariables("email", e.target.value);
            }}
          />
          {!user && (
            <TextField
              size="small"
              label="Parola"
              type="password"
              fullWidth
              value={selectedPassword ?? ""}
              onChange={(e) => {
                setSelectedPassword(e.target.value);
              }}
            />
          )}
          <Text bold>Rol:</Text>
          <TextField
            select
            size="small"
            value={selectedUser.role?.toString() ?? ""}
            onChange={(e) => {
              updateUserVariables("role", e.target.value);
            }}
          >
            {getUserRoleDropdownOptions().map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.display}
              </MenuItem>
            ))}
          </TextField>
          {selectedUser.role === IUserRoleEnum.Store ? (
            <>
              <Text bold>Magazin:</Text>
              <TextField
                select
                size="small"
                value={
                  storeList.find(
                    (store) => store.storeId === selectedUser.store?.toString(),
                  )?.storeId ?? ""
                }
                onChange={(e) => {
                  updateUserVariables("store", e.target.value);
                }}
              >
                {storeList
                  .map((store) => {
                    return {
                      id: store.storeId ?? "",
                      display: store.name ?? "",
                    };
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.display}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          ) : selectedUser.role === IUserRoleEnum.Producer ? (
            <>
              <Text bold>Producator:</Text>
              <TextField
                select
                size="small"
                value={
                  producerList.find(
                    (producer) =>
                      producer.producerId === selectedUser.producer?.toString(),
                  )?.producerId ?? ""
                }
                onChange={(e) => {
                  updateUserVariables("producer", e.target.value);
                }}
              >
                {producerList
                  .map((producer) => {
                    return {
                      id: producer.producerId ?? "",
                      display: producer.name ?? "",
                    };
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.display}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          ) : selectedUser.role === IUserRoleEnum.Warehouse ? (
            <>
              <Text bold>Depozit:</Text>
              <TextField
                select
                size="small"
                value={
                  warehouseList.find(
                    (warehouse) =>
                      warehouse.warehouseId ===
                      selectedUser.warehouse?.toString(),
                  )?.warehouseId ?? ""
                }
                onChange={(e) => {
                  updateUserVariables("warehouse", e.target.value);
                }}
              >
                {warehouseList
                  .map((warehouse) => {
                    return {
                      id: warehouse.warehouseId ?? "",
                      display: warehouse.title ?? "",
                    };
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.display}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button variant="contained" onClick={onAction}>
            {user ? "Editeaza" : "Adauga"}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
