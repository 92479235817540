import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./locales/i18n";
import App from "containers/App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import CloseIcon from "@mui/icons-material/Close";
import common_en from "./translations/en.json";
import { BrowserRouter } from "react-router-dom";
import store from "store/store";
import { MyThemeProvider } from "common/theme/ThemeProvider";
import { CssBaseline, IconButton } from "@mui/material";
import { closeSnackbar, SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

const languageOptions = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "i18nextLng",

  caches: ["localStorage"],
  excludeCacheFor: ["cimode"],
};

i18next.use(LanguageDetector).init({
  detection: languageOptions,
  interpolation: { escapeValue: false },
  supportedLngs: ["en"],
  fallbackLng: "en",
  resources: {
    en: {
      common: common_en,
    },
  },
  debug: false,
});

root.render(
  <MyThemeProvider>
    <CssBaseline />
    <Provider store={store}>
      <SnackbarProvider
        style={{
          zIndex: 999999,
        }}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        )}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  </MyThemeProvider>,
);
