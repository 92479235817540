import { styled } from "@mui/material";

export const CartWrapper = styled("div")`
  width: 70%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
