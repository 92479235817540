import { useEffect, useRef } from "react";
import { Backdrop, Fade, Modal as MuiModal, Stack } from "@mui/material";
interface IModalProps {
  isVisible?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  minWidth: "30rem",
  minHeight: "400px",
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "1.5rem",
};

export const Modal = ({ isVisible, onClose, children }: IModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        isVisible && onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <MuiModal
      open={Boolean(isVisible)}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={Boolean(isVisible)}>
        <Stack sx={{ ...style, overflow: "auto" }}>{children}</Stack>
      </Fade>
    </MuiModal>
  );
};
