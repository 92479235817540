import Table from "components/Table";
import { useEffect } from "react";
import { ProductRow } from "./components/ProductItem/ProductRow";
import { HeaderRow } from "./components/ProductItem/HeaderRow";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";
import { loadProducers, loadWarehouses, setPagination } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPagination,
  selectProductsList,
  selectTotalPagesCount,
  selectWarehouseList,
} from "./slice/selectors";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";
import { getProducer, getWarehouse } from "utils/localStorage";
import { MenuItem, TextField } from "@mui/material";

export const ProductsTable = () => {
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const productsList = useSelector(selectProductsList);
  const rows = productsList
    ? productsList.map((product) => <ProductRow product={product} />)
    : [];
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const warehouse = getWarehouse();
  const producer = getProducer();
  const user = useSelector(selectUser);
  const warehouseDropdownList = useSelector(selectWarehouseList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.role === IUserRoleEnum.Producer) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            producerId: producer?.producerId,
          },
        }),
      );
    } else if (user?.role === IUserRoleEnum.Warehouse) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            warehouseId: warehouse?.warehouseId,
          },
        }),
      );
    } else if (user?.role === IUserRoleEnum.Store) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            warehouseId: warehouse?.warehouseId,
            isActive: true,
            hasStock: true,
          },
        }),
      );
    } else {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
          },
        }),
      );
    }
    dispatch(loadWarehouses());
    dispatch(loadProducers());
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  const onSelect = (warehouseId: string) => {
    if (pagination) {
      if (user?.role === IUserRoleEnum.Store) {
        dispatch(
          setPagination({
            pagination: {
              ...pagination,
              warehouseId,
              isActive: true,
            },
          }),
        );
      } else {
        dispatch(
          setPagination({
            pagination: {
              ...pagination,
              warehouseId,
            },
          }),
        );
      }
    }
  };

  return (
    <>
      <div>
        {user?.role === IUserRoleEnum.Store && (
          <TextField
            size="small"
            placeholder="Depozit"
            select
            sx={{ width: "20rem" }}
            value={pagination.warehouseId ?? ""}
            onChange={(e) => onSelect(e.target.value)}
          >
            <MenuItem value={"Toate"}>Toate</MenuItem>
            {warehouseDropdownList.map((item) => (
              <MenuItem key={item.warehouseId} value={item.warehouseId}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <div className="mt-2">
        <Table
          headerRow={<HeaderRow />}
          rows={rows}
          loading={loading}
          notFoundText="Nu s-au gasit produse!"
        />
        <PaginationWrapper>
          <CustomPagination
            pagesCount={totalPagesCount}
            pageSize={pagination.pageSize}
            pageNumber={pagination.pageNumber}
            onSelectPage={selectPage}
          />
        </PaginationWrapper>
      </div>
    </>
  );
};
