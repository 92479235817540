import { styled } from "@mui/material";

export const RowWrapper = styled("div")`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
  margin: 1rem 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.text.primary}`};
  padding-bottom: 15px;
`;
