import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const LinkWrapper = styled(Link)`
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: #646777;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #6372ff;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};

    &:before {
      opacity: 1;
    }
  }
`;

export const LinkWrapperDiv = styled("div")`
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: #646777;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #6372ff;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    color: #fafbfe;

    &:before {
      opacity: 1;
    }
  }
`;
