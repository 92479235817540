import { IUserRoleEnum } from "openapi/types";
import { RouteVar } from "utils/constants";
import { ListItemWrapper } from "../atoms/ListItemWrapper";
import { TopbarAdmin } from "../components/TopbarAdmin";

export const rolesMenu: {
  [key in IUserRoleEnum]: (location: string) => JSX.Element;
} = {
  [IUserRoleEnum.Store]: (location) => (
    <>
      <ListItemWrapper
        active={location === RouteVar.mainpage.Route}
        to={RouteVar.mainpage.Route}
      >
        Dashboard
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.productsPage.Route}
        to={RouteVar.productsPage.Route}
      >
        Produse
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.ordersPage.Route}
        to={RouteVar.ordersPage.Route}
      >
        Comenzi
      </ListItemWrapper>
    </>
  ),
  [IUserRoleEnum.Producer]: (location) => (
    <>
      <ListItemWrapper
        active={location === RouteVar.mainpage.Route}
        to={RouteVar.mainpage.Route}
      >
        Dashboard
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.productsPage.Route}
        to={RouteVar.productsPage.Route}
      >
        Produse
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.ordersPage.Route}
        to={RouteVar.ordersPage.Route}
      >
        Raport vanzari
      </ListItemWrapper>
    </>
  ),
  [IUserRoleEnum.Warehouse]: (location) => (
    <>
      <ListItemWrapper
        active={location === RouteVar.mainpage.Route}
        to={RouteVar.mainpage.Route}
      >
        Dashboard
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.productsPage.Route}
        to={RouteVar.productsPage.Route}
      >
        Produse
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.ordersPage.Route}
        to={RouteVar.ordersPage.Route}
      >
        Comenzi
      </ListItemWrapper>
    </>
  ),
  [IUserRoleEnum.SysAdmin]: (location) => (
    <>
      <TopbarAdmin />
      <ListItemWrapper
        active={location === RouteVar.productsPage.Route}
        to={RouteVar.productsPage.Route}
      >
        Produse
      </ListItemWrapper>
      <ListItemWrapper
        active={location === RouteVar.ordersPage.Route}
        to={RouteVar.ordersPage.Route}
      >
        Comenzi
      </ListItemWrapper>
    </>
  ),
};
