import { Popover } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  anchor: HTMLButtonElement | undefined;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export function Popper({
  anchor,
  children,
  onClose,
  open,
}: Props): JSX.Element {
  return (
    <Popover
      sx={{
        position: "fixed",
        transform: "translateY(0.5rem)",
        overflow: "visible !important",
        "& .MuiPopover-paper": {
          overflow: "visible !important",
        },
        zIndex: 100,
      }}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </Popover>
  );
}
