import Modal from "components/Modal";
import { createProduct, updateProduct } from "containers/ProductsPage/slice";
import {
  selectPagination,
  selectProducerList,
  selectWarehouseList,
} from "containers/ProductsPage/slice/selectors";
import { IProductIn, IUserRoleEnum } from "openapi/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductIn } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { Button, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import _ from "lodash";
import { selectUser } from "containers/App/slice/selectors";
import { getProducer, getWarehouse } from "utils/localStorage";
import { Text } from "atoms";
import { ImageUploader } from "components/ImageUploader/ImageUploader";
import { FieldWrapper } from "./atoms/FieldWrapper";

interface IProductModalProps {
  onClose: () => void;
  product?: IProductIn;
}

interface IProductErrors {
  name?: string;
  price?: string;
  image?: string;
}

export const AddProductModal = ({ onClose, product }: IProductModalProps) => {
  const [selectedProduct, setSelectedProduct] = useState<IProductIn>(
    new ProductIn(),
  );
  const [selectedProductImage, setSelectedProductImage] = useState<File | null>(
    null,
  );
  const [errors, setErrors] = useState<IProductErrors>({});
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>(
    product?.warehouse ?? "",
  );
  const [selectedProducer, setSelectedProducer] = useState<string>(
    product?.producer ?? "",
  );
  const user = useSelector(selectUser);
  const currentProducer = getProducer();
  const currentWarehouse = getWarehouse();
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();
  const producerList = useSelector(selectProducerList);
  const warehouseList = useSelector(selectWarehouseList);

  useEffect(() => {
    if (product && product.warehouse && product.producer) {
      setSelectedProduct({ ...product });
      setSelectedProducer(product.producer);
      setSelectedWarehouse(product.warehouse);
    } else {
      if (user?.role === IUserRoleEnum.Producer) {
        setSelectedProducer(currentProducer.producerId);
      } else if (user?.role === IUserRoleEnum.Warehouse) {
        setSelectedWarehouse(currentWarehouse.warehouseId);
      }
    }
  }, []);

  const onAction = () => {
    let error: IProductErrors = {
      name: undefined,
      price: undefined,
      image: undefined,
    };

    if (!selectedProduct.name || selectedProduct.name === "")
      error.name = "Produsul nu are nume!";
    if (!selectedProduct.price || selectedProduct.price === 0)
      error.price = "Produsul nu are pret!";
    if (!product && !selectedProductImage)
      error.image = "Produsul nu are poza!";
    if (error.name || error.price || error.image) {
      setErrors(error);
      return;
    }
    if (selectedProductImage !== null) {
      dispatch(
        createProduct({
          image: selectedProductImage,
          product: {
            ...selectedProduct,
            warehouse: selectedWarehouse,
            producer: selectedProducer,
          },
          pagination,
        }),
      );
    } else {
      dispatch(
        updateProduct({
          product: {
            ...selectedProduct,
            warehouse: selectedWarehouse,
            producer: selectedProducer,
          },
          pagination,
        }),
      );
    }
    onClose();
  };

  const updateProductVariables = (
    key: keyof IProductIn,
    value: string | number | boolean,
  ) => {
    setSelectedProduct({
      ...selectedProduct,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Stack gap="1rem" className="mt-3">
          <Text bold fontSize="20px" style={{ marginBottom: "2rem" }}>
            Informații produs
          </Text>
          <TextField
            size="small"
            label="Nume"
            fullWidth
            disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
            value={selectedProduct?.name ?? ""}
            onChange={(e) => {
              updateProductVariables("name", e.target.value);
              setErrors({ ...errors, name: undefined });
            }}
          />
          {errors.name && (
            <Text color="red" bold>
              {errors.name}
            </Text>
          )}
          <TextField
            size="small"
            label="Descriere"
            fullWidth
            disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
            value={selectedProduct?.description ?? ""}
            onChange={(e) => {
              updateProductVariables("description", e.target.value);
            }}
          />
          {user?.role !== IUserRoleEnum.Warehouse && (
            <FieldWrapper>
              <Text bold>Depozit:</Text>
              <TextField
                select
                size="small"
                value={selectedWarehouse ?? ""}
                onChange={(e) => {
                  setSelectedWarehouse(e.target.value);
                }}
              >
                {warehouseList
                  .map((item) => {
                    return {
                      id: item.warehouseId ?? "",
                      display: item.title ?? "",
                    };
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.display}
                    </MenuItem>
                  ))}
              </TextField>
            </FieldWrapper>
          )}
          {user?.role === IUserRoleEnum.SysAdmin && (
            <FieldWrapper>
              <Text bold>Producator:</Text>
              <TextField
                select
                size="small"
                value={selectedProducer ?? ""}
                onChange={(e) => {
                  setSelectedProducer(e.target.value);
                }}
              >
                {producerList
                  .map((item) => {
                    return {
                      id: item.producerId ?? "",
                      display: item.name ?? "",
                    };
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.display}
                    </MenuItem>
                  ))}
              </TextField>
            </FieldWrapper>
          )}
          {user?.role !== IUserRoleEnum.Producer && (
            <FieldWrapper>
              <Text bold>Stare:</Text>
              <TextField
                select
                size="small"
                value={selectedProduct.isActive ? "ACTIVE" : "INACTIVE"}
                onChange={(e) => {
                  const id = e.target.value;
                  if (id === "ACTIVE") updateProductVariables("isActive", true);
                  else if (id === "INACTIVE")
                    updateProductVariables("isActive", false);
                }}
              >
                {[
                  { id: "ACTIVE", display: "Activ" },
                  { id: "INACTIVE", display: "Inactiv" },
                ].map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.display}
                  </MenuItem>
                ))}
              </TextField>
            </FieldWrapper>
          )}
          <FieldWrapper>
            <Text bold>Stoc:</Text>
            <TextField
              size="small"
              type="number"
              sx={{ width: "8rem" }}
              value={selectedProduct.stock ?? 0}
              onChange={(e) => {
                updateProductVariables("stock", e.target.value);
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Text bold>Pret:</Text>
            <TextField
              size="small"
              type="number"
              sx={{ width: "8rem" }}
              disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
              value={selectedProduct.price ?? 0}
              onChange={(e) => {
                updateProductVariables("price", e.target.value);
                setErrors({ ...errors, price: undefined });
              }}
            />
          </FieldWrapper>
          {errors.price && (
            <Text color="red" bold>
              {errors.price}
            </Text>
          )}
          <FieldWrapper>
            <Text bold>Poza:</Text>
            <ImageUploader
              image={selectedProduct.picture}
              onChange={(image) => {
                setSelectedProductImage(image);
                setErrors({ ...errors, image: undefined });
              }}
            />
            {errors.image && (
              <Text color="red" bold>
                {errors.image}
              </Text>
            )}
          </FieldWrapper>
        </Stack>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button variant="contained" onClick={onAction}>
            {product ? "Editeaza" : "Adauga"}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
