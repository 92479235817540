import { IUser } from "openapi/types";
import { Stack, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { TopbarProfile } from "./TopbarProfile";
import { rolesMenu } from "../utils/rolesMenu";

interface Props {
  user: IUser;
}

export function UserMenu({ user }: Props): JSX.Element {
  const role = user.role;
  const location = useLocation();

  if (!role) {
    return <></>;
  }

  return (
    <Stack
      ml="1rem"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack flexDirection="row" alignItems="center" gap="1.75rem">
        {rolesMenu[role](location.pathname)}
      </Stack>
      <div className="d-flex justify-content-center align-items-center">
        <Typography
          mr="0.5rem"
          color="GrayText"
        >{`${user.firstName} ${user.lastName}`}</Typography>
        <TopbarProfile />
      </div>
    </Stack>
  );
}
