import { AppBar, styled, Toolbar, useTheme } from "@mui/material";
import { RouteVar } from "utils/constants";
import { TitleWrapper } from "./atoms/TitleWrapper";
import { useSelector } from "react-redux";
import { selectUser } from "containers/App/slice/selectors";
import { UserMenu } from "./components/UserMenu";
import { NoUserMenu } from "./components/NoUserMenu";
import { useEffect, useState } from "react";

export function Navbar(): JSX.Element {
  const [isAtTop, setIsAtTop] = useState(true);
  const user = useSelector(selectUser);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsAtTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "transparent",
        width: "100%",
        left: 0,
        top: "1rem",
        overflow: "visible",
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "4rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <MyToolbar isLoggedIn={Boolean(user)} isAtTop={isAtTop}>
        <TitleWrapper to={RouteVar.mainpage.Route}>ALTFELDISTRIB</TitleWrapper>
        <div style={{ flex: 1 }}>
          {user ? <UserMenu user={user} /> : <NoUserMenu />}
        </div>
      </MyToolbar>
    </AppBar>
  );
}

const MyToolbar = styled(Toolbar)<{ isAtTop: boolean; isLoggedIn: boolean }>`
  justify-content: "space-between;
  height: 100%;
 width:${({ isLoggedIn }) => (!isLoggedIn ? "100%" : "100%")};
  gap: 1rem;
  border-radius:0.125rem;
  background: ${({ isAtTop }) =>
    `rgba(254, 254, 254, ${isAtTop ? "0" : "0.33"})`};
  box-shadow:${({ isAtTop }) =>
    `0 4px 30px rgba(0, 0, 0, ${isAtTop ? "0" : "0.1"}))`};
  backdrop-filter: ${({ isAtTop }) => (isAtTop ? "blur(0px)" : "blur(4px)")};
  -webkit-backdrop-filter: ${({ isAtTop }) =>
    isAtTop ? "blur(0px)" : "blur(4px)"};
  border:${({ isAtTop }) =>
    isAtTop
      ? "1px solid rgba(204, 189, 189,0)"
      : "1px solid rgba(204, 189, 189, 0.21)"};
  // @media (max-width:100rem){
  //   width:${({ isLoggedIn }) => (!isLoggedIn ? "100%" : "80%")};
  // }
  // @media (max-width:81rem){
  //   width:${({ isLoggedIn }) => (!isLoggedIn ? "100%" : "90%")};
  // }
  // @media (max-width:74rem){
  //   width:100%;
  // }
`;
