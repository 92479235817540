import { useSelector } from "react-redux";
import { selectOrdersList } from "./slice/selectors";
import { OrdersTable } from "./components/OrderTable/OrdersTable";
import { Paper } from "@mui/material";
import { PageWrapper } from "atoms/PageWrapper";

export const OrdersPage = () => {
  const ordersList = useSelector(selectOrdersList);

  return (
    <PageWrapper>
      <Paper variant="content">
        <OrdersTable ordersList={ordersList} />
      </Paper>
    </PageWrapper>
  );
};
