import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const ListItemWrapper = styled(Link)<{ active: boolean }>`
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  display: block;
  text-decoration: inherit;
  border: none;
  color: ${({ theme }) => theme.palette.common.black} !important;
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:after {
    display: block; /* Display on hover */
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: ${({ active }) => (active ? "100%" : "0%")};
    height: 2px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    transition: width 0.2s;
  }

  &:hover:after {
    display: block;
    width: 100%;
  }
`;

export const ListItemWrapperDiv = styled("div")`
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.common.black} !important;
  position: relative;
  display: block;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }

  &:after {
    display: block; /* Display on hover */
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0%;
    height: 2px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    transition: width 0.2s;
  }

  &:hover:after {
    display: block;
    width: 100%;
  }
`;
