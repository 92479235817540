import { createTheme, PaletteColor, ThemeProvider } from "@mui/material";
import React from "react";
import { useState } from "react";

interface ThemeContext {
  lightMode: boolean;
  switchTheme: () => void;
}

interface Param {
  children?: React.ReactNode;
  values?: Partial<ThemeContext>;
}

export const themeContext = React.createContext<ThemeContext>({
  lightMode: true,
  switchTheme: () => null,
});

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: string): PaletteColor =>
  augmentColor({ color: { main: mainColor } });

export function MyThemeProvider(param: Param): JSX.Element {
  const [lightMode, setLightMode] = useState(true);

  function switchTheme(): void {
    const theme = localStorage.getItem("theme");
    const current = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", current);
    setLightMode(!lightMode);
  }

  return (
    <themeContext.Provider value={{ lightMode, switchTheme, ...param.values }}>
      <ThemeProvider theme={lightTheme}>{param.children}</ThemeProvider>
    </themeContext.Provider>
  );
}

const PRIMARY_MAIN = "rgb(0,110,227)";
const PRIMARY_LIGHT = "rgb(192,223,255)";
const SECONDARY_MAIN = "rgb(0,173,167)";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
    },
    secondary: {
      main: SECONDARY_MAIN,
    },
    background: {
      default: "rgb(249,249,249)",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          "@media (min-width: 600px)": {
            minHeight: "auto",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "content" },
          style: ({ theme }) => ({
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
            border: `1px solid ${theme.palette.grey[400]}`,
            flex: 1,
          }),
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.5)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: PRIMARY_MAIN,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: PRIMARY_MAIN,
          },
        },
      },
    },
  },
});
