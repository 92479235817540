import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { Button, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { createStore, updateStore } from "containers/StoresPage/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IStoreIn } from "openapi/types";
import { StoreIn } from "utils/interfaces/interfaces";
import { Dropdown } from "components/Dropdown/Dropdown";
import { getPaymentMethodDropdownOptions } from "utils/enumUtils";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";

interface IStoreModalProps {
  onClose: () => void;
  store?: IStoreIn;
}

export const AddStoreModal = ({ onClose, store }: IStoreModalProps) => {
  const [selectedStore, setSelectedStore] = useState<IStoreIn>(new StoreIn());
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (store) {
      setSelectedStore(store);
    }
  }, []);

  const onAction = () => {
    console.log("selectedStore", selectedStore);
    return;
    if (store) {
      dispatch(updateStore({ store: selectedStore, pagination }));
    } else {
      dispatch(createStore({ store: selectedStore, pagination }));
    }
    setSelectedStore(new StoreIn());
    onClose();
  };

  const updateStoreVariables = (key: keyof IStoreIn, value) => {
    setSelectedStore({
      ...selectedStore,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Stack gap="1rem">
          <Text bold fontSize="20px" style={{ marginBottom: "2rem" }}>
            Informatii magazin
          </Text>
          <TextField
            size="small"
            label="Nume"
            fullWidth
            value={selectedStore?.name ?? ""}
            onChange={(e) => {
              updateStoreVariables("name", e.target.value);
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Adresa"
            value={selectedStore?.storeAddress?.address ?? ""}
            onChange={(e) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                address: e.target.value,
              });
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Oras"
            value={selectedStore?.storeAddress?.city ?? ""}
            onChange={(e) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                city: e.target.value,
              });
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Judet"
            value={selectedStore?.storeAddress?.county ?? ""}
            onChange={(e) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                county: e.target.value,
              });
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Tara"
            value={selectedStore?.storeAddress?.country ?? ""}
            onChange={(e) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                country: e.target.value,
              });
            }}
          />
          <Text bold>Metodă de plată:</Text>
          <TextField
            select
            size="small"
            value={selectedStore.paymentMethod?.toString() ?? ""}
            onChange={(e) => {
              updateStoreVariables("paymentMethod", e.target.value);
            }}
          >
            {getPaymentMethodDropdownOptions().map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.display}
              </MenuItem>
            ))}
          </TextField>
          <Text bold>Termen de plată:</Text>
          <TextField
            size="small"
            style={{ width: "25%" }}
            type="number"
            value={selectedStore.paymentTerm}
            onChange={(e) => {
              updateStoreVariables("paymentTerm", e.target.value);
            }}
          />
        </Stack>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button variant="contained" onClick={onAction}>
            {store ? "Editeaza" : "Adauga"}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
