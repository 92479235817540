import { useEffect, useState } from "react";
import { ProductsTable } from "./ProductsTable";
import { AddProductModal } from "./components/ProductModal/AddProductModal";
import { loadWarehouses } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";
import { PageWrapper } from "atoms/PageWrapper";
import { Button, Paper } from "@mui/material";

export const ProductsPage = () => {
  const user = useSelector(selectUser);
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWarehouses());
  }, []);

  return (
    <PageWrapper>
      <Paper variant="content">
        <div style={{ display: "flex", justifyContent: "end" }}>
          {user?.role === IUserRoleEnum.SysAdmin ||
            (user?.role === IUserRoleEnum.Producer && (
              <Button
                variant="contained"
                style={{ width: "10%", marginBottom: "0.5rem" }}
                onClick={() => setAddModalVisibility(true)}
              >
                Add
              </Button>
            ))}
        </div>
        <ProductsTable />
        {addModalVisibility && (
          <AddProductModal onClose={() => setAddModalVisibility(false)} />
        )}
      </Paper>
    </PageWrapper>
  );
};
