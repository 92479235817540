import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface BarChartProps {
  title: string;
  xAxis: string;
  yAxis: string;
  series: string;
  data: [string, number][];
}

export const BarChart: React.FC<BarChartProps> = ({
  title,
  xAxis,
  yAxis,
  series,
  data,
}) => {
  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: xAxis,
      },
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: [
      {
        type: "column",
        name: series,
        data: data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
