import Table from "components/Table";
import { useEffect, useState } from "react";
import { StoreRow } from "./components/StoreItem/StoreRow";
import { AddStoreModal } from "./components/StoreModal/AddStoreModal";
import { HeaderRow } from "./components/StoreItem/HeaderRow";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";
import { setPagination } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPagination,
  selectStoreList,
  selectTotalPagesCount,
} from "./slice/selectors";
import { Button, Paper } from "@mui/material";
import { PageWrapper } from "../../atoms/PageWrapper";

export const StoresPage = () => {
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const storeList = useSelector(selectStoreList);
  const rows = storeList.map((store) => <StoreRow store={store} />);
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPagination({ pagination: { pageNumber: 0, pageSize: 10 } }));
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  return (
    <PageWrapper>
      <Paper variant="content">
        <div
          style={{ display: "flex", justifyContent: "end" }}
          className="mr-2 mt-2"
        >
          <Button
            variant="contained"
            style={{ width: "10%", marginBottom: "0.5rem" }}
            onClick={() => setAddModalVisibility(true)}
          >
            Add
          </Button>
        </div>
        <div className="mt-2">
          <Table
            headerRow={<HeaderRow />}
            rows={rows}
            loading={loading}
            notFoundText="Nu s-au găsit magazine"
          />
          <PaginationWrapper>
            <CustomPagination
              pagesCount={totalPagesCount}
              pageSize={pagination.pageSize}
              pageNumber={pagination.pageNumber}
              onSelectPage={selectPage}
            />
          </PaginationWrapper>
        </div>
        {addModalVisibility && (
          <AddStoreModal onClose={() => setAddModalVisibility(false)} />
        )}
      </Paper>
    </PageWrapper>
  );
};
