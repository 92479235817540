import { useState } from "react";
import { Wrapper } from "../atoms/Wrapper";
import { BackButton } from "./BackButton";
import TopbarMenuLinks from "./TopbarMenuLinks";
import { ListItemWrapperDiv } from "../atoms/ListItemWrapper";
import { RouteVar } from "utils/constants";
import { Button, Stack } from "@mui/material";
import { Popper } from "common/components/Popper";

export const TopbarAdmin = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
    undefined,
  );

  return (
    <Wrapper>
      <ListItemWrapperDiv>
        <Button
          sx={{
            color: "inherit",
            fontWeight: "inherit",
            "&:hover": {
              background: "transparent",
            },
          }}
          variant="text"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Administrație
        </Button>
      </ListItemWrapperDiv>
      <Popper
        anchor={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        open={Boolean(anchorEl)}
      >
        <Stack
          sx={{
            width: "15rem",
          }}
        >
          <TopbarMenuLinks
            title="Magazine"
            path={RouteVar.storesPage.Route}
            onClick={() => setAnchorEl(undefined)}
          />
          <TopbarMenuLinks
            title="Producători"
            path={RouteVar.producersPage.Route}
            onClick={() => setAnchorEl(undefined)}
          />
          <TopbarMenuLinks
            title="Depozite"
            path={RouteVar.warehousesPage.Route}
            onClick={() => setAnchorEl(undefined)}
          />
          <TopbarMenuLinks
            title="Plăți"
            path={RouteVar.paymentsPage.Route}
            onClick={() => setAnchorEl(undefined)}
          />
          <TopbarMenuLinks
            title="Useri"
            path={RouteVar.usersPage.Route}
            onClick={() => setAnchorEl(undefined)}
          />
        </Stack>
      </Popper>
    </Wrapper>
  );
};
