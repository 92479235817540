import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectUserStore } from "containers/App/slice/selectors";
import { loadOrder } from "./slice";
import { CartTable } from "./components/CartTable/CartTable";
import { loadWarehouses } from "containers/ProductsPage/slice";
import { PageWrapper } from "atoms/PageWrapper";
import { Paper } from "@mui/material";

export const CartPage = () => {
  const dispatch = useDispatch();
  const userStore = useSelector(selectUserStore);

  useEffect(() => {
    if (userStore && userStore.storeId) {
      dispatch(loadOrder({ storeId: userStore.storeId }));
    }
    dispatch(loadWarehouses());
  }, []);

  return (
    <PageWrapper>
      <Paper variant="content">
        <CartTable />
      </Paper>
    </PageWrapper>
  );
};
