import { Route, Routes, useNavigate } from "react-router-dom";
import { RouteVar } from "utils/constants";
import { StoresPage } from "containers/StoresPage/StoresPage";
import { selectAccessToken, selectUser } from "./slice/selectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { LandingPage } from "containers/LandingPage/LandingPage";
import { DashboardPage } from "containers/DashboardPage/DashboardPage";
import { ProductsPage } from "containers/ProductsPage/ProductsPage";
import { CartPage } from "containers/CartPage/CartPage";
import { OrdersPage } from "containers/OrdersPage/OrdersPage";
import { SingleOrderPage } from "containers/SingleOrderPage/SingleOrderPage";
import { ProducersPage } from "containers/ProducersPage/ProducersPage";
import { WarehousesPage } from "containers/WarehousesPage/WarehousesPage";
import { UsersPage } from "containers/UsersPage/UsersPage";
import { ToastMessage } from "components/ToastMessage/ToastMessage";
import { ProfilePage } from "containers/ProfilePage/ProfilePage";
import { IUserRoleEnum } from "openapi/types";
import { Navbar } from "containers/Layout/Navbar";
import { useTheme } from "@mui/material";

const App = () => {
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  const { palette } = useTheme();

  useEffect(() => {
    if (!accessToken) navigate(RouteVar.login.Route);
  }, [accessToken]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        backgroundColor: palette.background.default,
      }}
    >
      <Navbar />
      <div
        style={{
          marginTop: "6rem",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Routes>
          <Route path={RouteVar.login.Route} element={<LandingPage />} />
          <Route path={RouteVar.storesPage.Route} element={<StoresPage />} />
          <Route
            path={RouteVar.producersPage.Route}
            element={<ProducersPage />}
          />
          <Route
            path={RouteVar.warehousesPage.Route}
            element={<WarehousesPage />}
          />
          <Route path={RouteVar.usersPage.Route} element={<UsersPage />} />
          <Route path={RouteVar.mainpage.Route} element={<DashboardPage />} />
          <Route
            path={RouteVar.productsPage.Route}
            element={<ProductsPage />}
          />
          <Route path={RouteVar.cartPage.Route} element={<CartPage />} />
          <Route path={RouteVar.ordersPage.Route} element={<OrdersPage />} />
          {user?.role === IUserRoleEnum.Store && (
            <Route
              path={RouteVar.profilePage.Route}
              element={<ProfilePage />}
            />
          )}
          <Route
            path={RouteVar.singleOrderPage.Route}
            element={<SingleOrderPage />}
          />
        </Routes>
      </div>
      <ToastMessage />
    </div>
  );
};

export default App;
