import { useDispatch, useSelector } from "react-redux";
import { selectOrder } from "./slice/selectors";
import { useEffect, useState } from "react";
import { loadSingleOrder } from "./slice";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderProductsTable } from "components/OrderProductsTable/OrderProductsTable";
import { IWarehouseListOut } from "openapi/types";
import { selectWarehouseList } from "containers/ProductsPage/slice/selectors";
import { loadWarehouses } from "containers/ProductsPage/slice";
import { Wrapper } from "./atoms/Wrapper";
import { Text } from "atoms";
import { getTotalPriceOfProducts } from "utils/UtilFunctions";
import { Button, IconButton, Paper, Stack, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { getOrderStatusLabel } from "utils/enumUtils";
import { PageWrapper } from "atoms/PageWrapper";

export const SingleOrderPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const order = useSelector(selectOrder);
  const warehouseList = useSelector(selectWarehouseList);
  const [foundWarehouses, setFoundWarehouses] = useState<IWarehouseListOut[]>(
    [],
  );
  const { palette } = useTheme();

  useEffect(() => {
    const orderId = location.pathname.replace("/order/", "");
    dispatch(loadSingleOrder({ orderId }));
    dispatch(loadWarehouses());
  }, []);

  useEffect(() => {
    const list: IWarehouseListOut[] = [];
    warehouseList.map((warehouse) => {
      if (warehouse.warehouseId) {
        const products = order?.products?.filter((prod) => {
          if (prod.product?.warehouse === warehouse.warehouseId) return true;
          return false;
        });
        if (products && products.length > 0) {
          list.push(warehouse);
        }
      }
    });
    setFoundWarehouses(list);
  }, [warehouseList, order]);

  const onBack = () => {
    navigate("/orders");
  };

  return (
    <PageWrapper>
      <Paper variant="content">
        <Button
          sx={{ width: "8rem" }}
          onClick={onBack}
          startIcon={<ArrowBack />}
          variant="contained"
        >
          Inapoi
        </Button>
        <Wrapper mt="2rem">
          {order && order.products && order.products.length > 0 && (
            <>
              <Text bold fontSize="18px">
                Identificator comanda:{" "}
                <span style={{ color: palette.secondary.dark }}>
                  {order.orderId?.substring(0, 8)}
                </span>
              </Text>
              <OrderProductsTable
                warehouseList={foundWarehouses}
                order={order}
                disabled={true}
              />
              <Stack mt="auto">
                <Text className="mt-5" bold fontSize="18px">
                  Stare : {getOrderStatusLabel(order.orderState)}
                </Text>
                <Text bold fontSize="18px">
                  Pret total : {getTotalPriceOfProducts(order.products ?? [])}{" "}
                  RON
                </Text>
              </Stack>
            </>
          )}
        </Wrapper>
      </Paper>
    </PageWrapper>
  );
};
