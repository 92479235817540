import Table from "components/Table";
import { useEffect, useState } from "react";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";
import { loadPrerequisites, setPagination } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPagination,
  selectTotalPagesCount,
  selectUserList,
} from "./slice/selectors";
import { HeaderRow } from "./components/UserItem/HeaderRow";
import { UserRow } from "./components/UserItem/UserRow";
import { AddUserModal } from "./components/UserModal/AddUserModal";
import { PageWrapper } from "../../atoms/PageWrapper";
import { Button, Paper } from "@mui/material";

export const UsersPage = () => {
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const userList = useSelector(selectUserList);
  const rows = userList.map((user) => <UserRow user={user} />);
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPagination({ pagination: { pageNumber: 0, pageSize: 10 } }));
    dispatch(loadPrerequisites());
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  return (
    <PageWrapper>
      <Paper variant="content">
        <div
          style={{ display: "flex", justifyContent: "end" }}
          className="mr-2 mt-2"
        >
          <Button
            variant="contained"
            style={{ width: "10%", marginBottom: "0.5rem" }}
            onClick={() => setAddModalVisibility(true)}
          >
            Add
          </Button>
        </div>
        <div className="mt-2">
          <Table
            headerRow={<HeaderRow />}
            rows={rows}
            loading={loading}
            notFoundText="Nu s-au găsit useri"
          />
          <PaginationWrapper>
            <CustomPagination
              pagesCount={totalPagesCount}
              pageSize={pagination.pageSize}
              pageNumber={pagination.pageNumber}
              onSelectPage={selectPage}
            />
          </PaginationWrapper>
        </div>
        {addModalVisibility && (
          <AddUserModal onClose={() => setAddModalVisibility(false)} />
        )}
      </Paper>
    </PageWrapper>
  );
};
